import {
  createAsyncThunk, createSlice
} from '@reduxjs/toolkit'
import moment from 'moment-timezone'

import { catalogApi } from '../api'

const initialState = {
  userId: window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || 229165046,
  deliveryAddress: null,
  branches: [],
  isBranchClosed: false
}

export const fetchUserInfo = createAsyncThunk(
  'app/fetchUserInfo',
  async (_, { dispatch }) => {
    try {
      const response = await catalogApi.getDeliveryAddress()

      if (response.data) {
        dispatch(appActions.setDeliveryAddress(response.data?.attributes))
      }
    } catch (error) {
      console.error(error)
    }
  }
)

export const fetchBranches = createAsyncThunk(
  'app/fetchBranches',
  async (_, { dispatch }) => {
    try {
      const response = await catalogApi.getBranches()

      if (!response.data?.length) {
        return
      }

      if (response.data) {
        dispatch(appActions.setBranches(response.data.map(elem => elem.attributes)))
        dispatch(checkWorkTimeEveryMinute())
      }
    } catch (error) {
      console.error(error)
    }
  }
)
export const checkWorkTimeEveryMinute = createAsyncThunk(
  'app/checkWorkTimeEveryMinute',
  async (_, {
    dispatch, getState
  }) => {
    try {
      const state = getState().app
      const branch = state.branches[0]

      const workFrom = Number(branch.workFrom.split(':')[0])
      const workTo = Number(branch.workTo.split(':')[0])

      const hoursNow = moment().tz('Europe/Moscow').hours()
      dispatch(appActions.setClosingStatus((!(workFrom < hoursNow && hoursNow < workTo))))

      setInterval(() => {
        const hoursNow = moment().tz('Europe/Moscow').hours()
        dispatch(appActions.setClosingStatus((!(workFrom < hoursNow && hoursNow < workTo))))
      }, 1000 * 60)
    } catch (error) {
      console.error(error)
    }
  }
)


export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload
    },
    setDeliveryAddress: (state, action) => {
      state.deliveryAddress = action.payload
    },
    setBranches: (state, action) => {
      state.branches = action.payload
    },
    setClosingStatus: (state, action) => {
      state.isBranchClosed = action.payload
    }
  },
})

export const appActions = appSlice.actions
export const appReducer = appSlice.reducer