import {
  useCallback, useEffect, useState 
} from 'react'

import {
  createSearchParams,
  useLocation,
  useNavigate, useSearchParams 
} from 'react-router-dom'

const mergeFilters = (defaultFilters, filtersFromQuery) => {
  return Object.entries(defaultFilters).reduce((res, [key, value]) => ({
    ...res,
    [key]: filtersFromQuery[key] || value
  }), {})
}

export const useFilters = (defaultFilters) => {
  const [searchParams] = useSearchParams()
  const currentParams = Object.fromEntries([...searchParams])
  const navigate = useNavigate()
  const { pathname } = useLocation()
  
  const [filters, setFiltersState] = useState(mergeFilters(defaultFilters, currentParams))

  const setFilters = useCallback((newFilters) => {
    const newQueryParams = {
      ...currentParams, ...newFilters 
    }

    Object.entries(newQueryParams).forEach(([key, value]) => {
      if (value === null) {
        delete newQueryParams[key]
      }
    })

    navigate({
      pathname: pathname,
      search: `?${createSearchParams(newQueryParams)}`,
    })
  }, [pathname, searchParams])

  useEffect(() => {
    setFiltersState(mergeFilters(defaultFilters, currentParams))
  }, [searchParams])

  return [filters, setFilters]
}