export const debounce = (callback, ms) => {
  let timeoutId

  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
  
    timeoutId = setTimeout(() => callback(...args), ms)
  }
}