import React, { useState } from 'react'

import styled from 'styled-components'
import { useSwipeable } from 'react-swipeable'

import arrowLeftImg from '@/images/icons/arrow-left.png'
import { ReactComponent as CrossIcon } from '@/images/icons/cross-black.svg'

export const Slider = React.memo(({
  className = '', images = [] 
}) => {
  const [currentImgIndex, setCurrentImgIndex] = useState(0)
  const [fullSizeOpened, toggleFullSize] = useState(false)

  const handleNext = () => {
    setCurrentImgIndex(state => {
      if (state === images.length - 1) {
        return 0
      }

      return state + 1
    })
  }

  const handlePrev = () => {
    setCurrentImgIndex(state => {
      if (0 === state) {
        return images.length - 1
      }

      return state - 1
    })
  }


  const handlers = useSwipeable({ 
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev 
  })

  const handleOpenFullScreen = () => {
    toggleFullSize(true)
  }

  const handleCloseFullScreen = () => {
    toggleFullSize(false)
  }

  return (
    <>
      <SliderBox
        className={className}
        {...handlers}
      >
        <CurrentImgBox
          src={images[currentImgIndex]}
          onClick={handleOpenFullScreen}
        />

        {
          images.length > 1 &&
          <>
            <Arrow
              src={arrowLeftImg}
              onClick={handlePrev}
            />
            <Arrow
              src={arrowLeftImg}
              className="right"
              onClick={handleNext}
            />
          </>
        }
        
      </SliderBox>

      {
        fullSizeOpened &&
        <PreviewBox>
          <PreviewImg src={images[currentImgIndex]} />

          <StyledCrossIcon
            onClick={handleCloseFullScreen}
          />
        </PreviewBox>
      }
      
    </>
  )
})

const SliderBox = styled.div`
  width: 100%;
  height: 256px;
  /* background: rgba(118, 118, 128, 0.08); */
  border-radius: 8px;
  position: relative;
`

const CurrentImgBox = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Arrow = styled.img`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);

  &.right {
    right: 16px;
    left: unset;
    transform: rotate(180deg) translateY(50%);
  }
`

const PreviewBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${props => props.theme.backgrounds.main};
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PreviewImg = styled.img`
  height: 90%;
  width: 100%;
  object-fit: contain;
`

const StyledCrossIcon = styled(CrossIcon)`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  transition: all 0.4s linear;

  &:hover {
    filter: brightness(110%);
  }
  
  &:focus, &:active {
    filter: brightness(120%);
  }

  path {
    stroke: ${props => props.theme.text.main};
  }
`