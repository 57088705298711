export const formatPrice = (price) => {
  const splitted = [...String(price)].reverse()
  let result = ''

  splitted.forEach((symbol, index) => {
    if ((index + 1) % 3 === 0) {
      result += symbol + ' '
    } else {
      result += symbol
    }
  })

  return [...result].reverse().join('')
}