import React from 'react'

import { CatalogIcon } from './CatalogIcon'
import { BasketIcon } from './BasketIcon'
import { ProfileIcon } from './ProfileIcon'

export const SvgIcon = ({
  type, ...props 
}) => {
  switch(type) {
    case 'catalog':
      return <CatalogIcon {...props} />
    case 'basket':
      return <BasketIcon {...props} />
    case 'profile':
      return <ProfileIcon {...props} />
    default:
      return (
        <>
Icon not found
        </>
      )
  }
}
