import React from 'react'

import classNames from 'classnames'
import styled from 'styled-components'

export const ProductOptions = ({
  className = '', type = 'text', options = [], selected = [], onSelect 
}) => {
  const handleOptionClick = (value) => {
    onSelect([value])
  }

  return (
    <ProductOptionsBox className={className}>
      {options.map(option => (
        <Option
          key={option.value}
          className={classNames({ active: selected.includes(option.value) })}
          onClick={handleOptionClick}
        >
          {type === 'text' && option.text}
          {type === 'color' &&
            <Color style={{ background: option.color }} />
          }
        </Option>
      ))}
    </ProductOptionsBox>
  )
}

const ProductOptionsBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const Option = styled.div`
  margin-right: 8px;
  height: 24px;
  min-width: 24px;
  border: 1px solid #7E7E82;
  border-radius: 6px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 8px;
  text-align: center;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.quaternary};

  &.active {
    color: ${props => props.theme.text.main};
    border-color: ${props => props.theme.text.main};
  }
`

const Color = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
`
