import React, {
  useCallback,
  useEffect, useRef, useState 
} from 'react'

import { useNavigate } from 'react-router-dom'
import { catalogApi } from 'src/features/api'
import { ProductCard } from 'src/features/catalog/ProductCard'
import { SearchInput } from 'src/features/catalog/SearchInput'
import { Preloader } from 'src/ui/Preloader'
import styled from 'styled-components'

export const SearchPage = React.memo(({ className = '' }) => {
  const inputRef = useRef(null)
  const navigate = useNavigate()
  
  const [value, setValue] = useState('')
  const [products, setProducts] = useState(null)

  const handleCancelClick = () => {
    navigate(-1)
  }

  const handleSearch = useCallback(async (value) => {
    if (!value) {
      setValue(value)
      setProducts(null)

      return
    }

    const response = await catalogApi.search(value)

    setValue(value)

    setProducts(response.products)
  }, [])

  useEffect(() => {
    inputRef?.current?.focus()
  }, [inputRef])

  return (
    <SearchPageBox className={className}>
      <TopRow>
        <SearchInput
          inputRef={inputRef}
          onSearch={handleSearch}
        />

        <CancelBtn onClick={handleCancelClick}>
          Отмена
        </CancelBtn>
      </TopRow>

      {/* <StyledPreloader /> */}

      {
        !!value && !!products && !!products.length &&
        <CardsBox>
          {products.map(product => (
            <ProductCard
              data={{
                attributes: product, id: product.id 
              }}
              key={product.id}
            />
          ))}
        </CardsBox>
      }

      {
        !value &&
        <Empty>
          <EmptyText>
            Введите поисковый запрос
          </EmptyText>
        </Empty>
      }
      
      {
        !!value && !!products && !products.length &&
        <Empty>
          <EmptyTitle>
            Нет результатов
          </EmptyTitle>

          <EmptyText>
            По запросу «{value}» ничего не найдено.
            Попробуте снова.
          </EmptyText>
        </Empty>
      }
      
    </SearchPageBox>
  )
})

const SearchPageBox = styled.div`
  padding: 16px 16px 70px;
`

const TopRow = styled.div`
  display: flex;
  align-items: center;
`

const CancelBtn = styled.span`
  margin-left: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0;
  color: ${props => props.theme.text.tertiary};

  cursor: pointer;
  transition: all 0.4s linear;
  
  &:hover {
    filter: brightness(110%);
  }
  
  &:focus, &:active {
    filter: brightness(120%);
  }
`

const CardsBox = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 23px 16px;
`

const Empty = styled.div`
  width: 100%;
  padding-top: 176px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const EmptyTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.quaternary};
`

const EmptyText = styled.p`
  margin-top: 8px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.quaternary};
`

const StyledPreloader = styled(Preloader)``