import React, {
  useCallback, useEffect, useRef, useState
} from 'react'

import { BtnFilters } from 'src/features/catalog/BtnFilters'
import styled from 'styled-components'
import { ProductCard } from 'src/features/catalog/ProductCard'
import { BackButton } from 'src/features/catalog/BackButton'
import { Filters } from 'src/features/catalog/Filters'
import { SearchInput } from 'src/features/catalog/SearchInput'
import { catalogApi } from 'src/features/api'
import { useLoadOnScroll } from 'src/hooks/useLoadOnScroll'
import { useFilters } from 'src/hooks/useFilters'
import {
  useNavigate, useParams
} from 'react-router-dom'
import { Preloader } from 'src/ui/Preloader'
import { ROUTES } from 'src/constants/routes'
import { Button } from 'src/ui/Button'
import moment from 'moment'
import localization from 'moment/locale/ru'
import { useQuery } from '@tanstack/react-query'

moment.updateLocale('ru', localization)

export const ProfilePage = ({ className = '' }) => {
  const navigate = useNavigate()

  const {
    data, error, isLoading
  } = useQuery({
    queryKey: ['getOrdersHistory'], queryFn: () => catalogApi.getOrdersHistory(), placeholderData: [], select: data => data.data
  })

  const handleBackClick = useCallback(() => {
    navigate(ROUTES.home)
  }, [])

  const handleRepeatOrderClick = (id) => {
    navigate(`/order/${id}`)
  }


  return (
    <ProfileBox className={className}>
      <TopRow>
        <BackButton onClick={handleBackClick} />

        <Title>Профиль</Title>

        <TopRowEmptyDiv />
      </TopRow>

      <HistoryTitle>История заказов</HistoryTitle>

      {
        !isLoading && !!data?.length && data.map(order => (
          <OrderCard key={order.id}>
            <OrderDate>
              {moment(order.createdAt).format('D.MM.YYYY г. в HH:mm')}
            </OrderDate>

            <OrderImgsBox>
              {
                order.products.map(product => (
                  <OrderImg
                    key={product.id}
                    src={product.images?.[0]?.url}
                  />
                ))
              }
            </OrderImgsBox>

            <OrderAmount>
              <OrderAmountTitle>
                Сумма
              </OrderAmountTitle>
              <OrderAmountValue>
                {order.totalPrice} Р
              </OrderAmountValue>
            </OrderAmount>

            <OrderBtn onClick={() => handleRepeatOrderClick(order.id)}>
              Повторить заказ
            </OrderBtn>
          </OrderCard>
        ))
      }

      {
        !isLoading && !data?.length &&
          <Empty>
          Здесь пока пусто
          </Empty>
      }

      {
        isLoading &&
        <PreloaderBox>
          <Preloader />
        </PreloaderBox>
      }
    </ProfileBox>
  )
}

const ProfileBox = styled.div`
  padding: 16px 16px 90px;
`

const TopRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: ${props => props.theme.text.main};
`

const PreloaderBox = styled.div`
  height: calc(100vh - 350px);
  position: relative;
`

const TopRowEmptyDiv = styled.div`
  width: 32px;
`

const HistoryTitle = styled.p`
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: ${props => props.theme.text.main};
`

const OrderCard = styled.div`
  margin-bottom: 16px;
  width: 100%;
  border-radius: 8px;
  background: ${props => props.theme.backgrounds.main};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  padding: 16px;
`

const OrderDate = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: ${props => props.theme.text.main};
`

const OrderImgsBox = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 14px;
  width: 100%;
  flex-wrap: wrap;
`

const OrderImg = styled.img`
  width: 48px;
  height: 40px;
  object-fit: contain;
`

const OrderAmount = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid rgba(60, 60, 67, 0.29);
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const OrderAmountTitle = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${props => props.theme.text.main};
`

const OrderAmountValue = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  color: ${props => props.theme.text.main};
`

const OrderBtn = styled(Button)`
  margin-top: 16px;
  width: 100%;
  height: 40px;
`

const Empty = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: ${props => props.theme.text.main};
`

