import React, {
  useEffect, useState
} from 'react'

import { Slider } from 'src/ui/Slider'
import styled from 'styled-components'
import { ProductOptions } from 'src/features/catalog/ProductOptions'
import { Button } from 'src/ui/Button'
import { BackButton } from 'src/features/catalog/BackButton'
import {
  useNavigate, useParams
} from 'react-router-dom'
import { Preloader } from 'src/ui/Preloader'
import { catalogApi } from 'src/features/api'
import { useBasket } from 'src/features/basket/useBasket'
import { getBasketProducts } from 'src/features/basket/selectors'
import { Counter } from 'src/ui/Counter'
import classNames from 'classnames'

import { formatPrice } from '../utils/formatters'

export const ProductPage = ({ className = '' }) => {
  const { id: productId } = useParams()

  const products = getBasketProducts()
  const navigate = useNavigate()

  const [loading, toggleLoading] = useState(true)
  const [data, setData] = useState({})

  const [selectedFilters, setSelectedFilters] = useState({})

  const {
    addProduct, removeProduct
  } = useBasket()

  const productCountInBasket = products.find(product => product.id === Number(data.id))?.count || 0

  useEffect(() => {
    const fetchData = async () => {
      const response = await catalogApi.getProductInfo(productId)

      const data = response.data.attributes

      setData({
        ...data, id: Number(productId)
      })

      const selectedFilters = data.filters.reduce((r, filter) => ({
        ...r, [filter.name]: filter.values[0]
      }), {})

      setSelectedFilters(selectedFilters)

      toggleLoading(false)
    }

    fetchData()
  }, [productId])

  const handleSelectFilter = (name, value) => {
    const availableFilters = data.availableFilters
    const filters = data.filters

    const newSelectedFilters = {
      ...selectedFilters,
      [name]: value
    }

    const findExistedFilter = (newSelectedFilters) => {
      return availableFilters.find(filter => {
        let isEqual = true

        const mappedFilter = filter.values.reduce((r, filter) => ({
          ...r, [filter.name]: filter.values
        }), {})

        Object.entries(newSelectedFilters).forEach(([key, value]) => {
          if (mappedFilter[key] !== value) {
            isEqual = false
          }
        })

        return isEqual
      })
    }

    const setNewFiltersAndProduct = (newFilter) => {
      setData(state => ({
        ...state, ...newFilter.product.attributes, id: Number(newFilter.product.id)
      }))

      setSelectedFilters(newFilter.values.reduce((r, filter) => ({
        ...r, [filter.name]: filter.values
      }), {}))
    }

    const existedFilter = findExistedFilter(newSelectedFilters)

    if (existedFilter) {
      setNewFiltersAndProduct(existedFilter)
    } else {
      const filterInFilters = filters.find(filter => filter.name === name)
      const filterIndex = filters.indexOf(filterInFilters)

      let newSelectedFilters = { [name]: value }

      if (filterIndex === 0) {
        const newFilter = findExistedFilter({ ...newSelectedFilters })

        setNewFiltersAndProduct(newFilter)

        return
      }

      for (let i = filterIndex - 1; i >= 0; i--) {
        const filterName = filters[i].name
        const currentFilterValue = selectedFilters[filterName]

        const newFilter = findExistedFilter({
          ...newSelectedFilters, [filterName]: currentFilterValue
        })

        if (newFilter) {
          if (i === 0) {
            setNewFiltersAndProduct(newFilter)
          } else {
            newSelectedFilters[filterName] = currentFilterValue
          }
        } else {
          if (i === 0) {
            const newFilter = findExistedFilter(newSelectedFilters)

            setNewFiltersAndProduct(newFilter)
          }
        }
      }
    }
  }

  const handleAddProductClick = () => {
    addProduct(data)
  }

  const handleRemoveProductClick = () => {
    removeProduct(data)
  }

  return (
    <ProductPageBox className={className}>
      <TopRow>
        <BackButton />
      </TopRow>

      {
        loading &&
        <PreloaderBox>
          <Preloader />
        </PreloaderBox>
      }

      {
        !loading &&
        <>
          <Slider images={data.images.data.map(img => img.attributes.url)} />

          <Title>
            {data.name}
          </Title>

          {
            !!data.vendorCode &&
            <Articul>Арт. {data.vendorCode}</Articul>
          }

          {
            !!data.options &&
            <MiddleRow>
              <MiddleColumn>
                <OptionLabel>
                  {data.options[0]?.name}
                </OptionLabel>
                <StyledProductOptions
                  options={data.options[0]?.values}
                  type={data.options[0]?.type}
                />
              </MiddleColumn>

              <MiddleColumn>
                <OptionLabel>
                  {data.options[1]?.name}
                </OptionLabel>
                <StyledProductOptions
                  options={data.options[1]?.values}
                  type={data.options[1]?.type}
                />
              </MiddleColumn>
            </MiddleRow>
          }

          {
            !!data.filters && data.filters.map(filter => (
              <React.Fragment key={filter.id}>
                <FilterLabel>
                  {filter.name}
                </FilterLabel>

                <FiltersBox>
                  {
                    filter.values.map(value => (
                      <Filter
                        key={value}
                        onClick={() => handleSelectFilter(filter.name, value)}
                        className={classNames({ active: selectedFilters[filter.name] === value })}
                      >
                        {value} {value.unit}
                      </Filter>
                    ))
                  }
                </FiltersBox>
              </React.Fragment>
            ))
          }

          <PriceRow>
            <PriceLeft>
              {!!data.old_price && <OldPrice>{data.old_price} ₽</OldPrice>}
              <Price>{formatPrice(data.price)} ₽</Price>
            </PriceLeft>

            {
              productCountInBasket === 0 &&
              <Button onClick={handleAddProductClick}>
                В корзину
              </Button>
            }

            {
              productCountInBasket !== 0 &&
              <Counter
                count={productCountInBasket}
                onPlus={handleAddProductClick}
                onMinus={handleRemoveProductClick}
              />
            }

          </PriceRow>

          {
            !!data.description &&
            <Description>
              <DescriptionTitle>
                Описание
              </DescriptionTitle>
              {data.description}
            </Description>
          }

        </>
      }
    </ProductPageBox>
  )
}

const ProductPageBox = styled.div`
  padding: 16px 16px 90px;
`

const Title = styled.p`
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.main};
`

const Articul = styled.p`
  margin-top: 10px;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.quaternary};
`

const MiddleRow = styled.div`
  margin-top: 16px;
  display: flex;
`

const MiddleColumn = styled.div`
  width: 49%;
`

const OptionLabel = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.quaternary};
`

const StyledProductOptions = styled(ProductOptions)`
  margin-top: 8px;
  width: 100%;
`

const Price = styled.p`
  margin-top: 4px;
  font-weight: 500;
  font-size: 17px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.main};
`

const OldPrice = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.5px;
  text-decoration-line: line-through;
  color: ${props => props.theme.text.quaternary};
`

const PriceRow = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const PriceLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const Description = styled.div`
  margin-top: 25px;
  padding-top: 23px;
  border-top: 0.5px solid rgba(60, 60, 67, 0.29);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.main};
`

const DescriptionTitle = styled.p`
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.main};
`

const TopRow = styled.div`
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
`

const PreloaderBox = styled.div`
  height: calc(100vh - 350px);
  position: relative;
`

const FilterLabel = styled.p`
  margin-top: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #7E7E82;
`

const FiltersBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Filter = styled.div`
  margin: 8px 8px 0 0;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #7E7E82;
  border: 1px solid #7E7E82;
  border-radius: 6px;

  &.active {
    background: rgba(3, 126, 229, 0.16);
    border: 1px solid #037EE5;
    color: #037EE5;
  }
`