import React from 'react'

import styled from 'styled-components'

export const RangeFilter = ({ className = '' }) => {
  return (
    <RangeFilterBox className={className}>
      <Label>от</Label>
      <Input />

      <Divider>—</Divider>

      <Label>до</Label>
      <Input />
    </RangeFilterBox>
  )
}

const RangeFilterBox = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.span`
  margin-right: 8px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: ${props => props.theme.text.quaternary};
`

const Input = styled.input`
  height: 32px;
  width: 96px;
  border: 1px solid #7E7E82;
  border-radius: 8px;
  background: transparent;
  padding: 0 8px;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: ${props => props.theme.inputs.color};

  &::placeholder {
    color: ${props => props.theme.inputs.placeholder};
  }
`

const Divider = styled.span`
  margin: 0 8px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.24px;
  color: ${props => props.theme.text.quaternary};
`