import React from 'react'

import styled from 'styled-components'

export const Switch = ({
  className = '', checked, onChange 
}) => {
  return (
    <SwitchBox className={className}>
      <Input
        type="checkbox"
        checked={checked}
        onChange={e => onChange(e.target.checked)}
      />
      <Slider />
    </SwitchBox>
  )
}

const SwitchBox = styled.label`
  width: 51px;
  height: 31px;
  position: relative;
`

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.theme.backgrounds.secondary};
  border-radius: 16px;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 27px;
    width: 27px;
    border-radius: 50%;
    left: 2px;
    bottom: 2px;
    background: ${props => props.theme.backgrounds.main};
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2), 0px 3px 1px rgba(0, 0, 0, 0.1);
    transition: .4s;
  }

  ${Input}:checked + & {
    background-color: ${props => props.theme.text.tertiary};
  }

  ${Input}:checked + &:before {
    transform: translateX(20px);
  }
`