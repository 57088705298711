import {
  createAsyncThunk, createSlice 
} from '@reduxjs/toolkit'

import testImg from '@/images/sofa.png'

import { catalogApi } from '../api'

const initialState = { products: [] }

export const fetchBasket = createAsyncThunk(
  'basket/fetchBasket',
  async (userId) => {
    const response = await catalogApi.getBasket(userId)

    const products = response.data?.attributes?.cart_products?.data || []

    return products.map(product => ({
      id: product.attributes.product.data.id, ...product.attributes.product.data.attributes, count: product.attributes.count, 
    }))
  }
)

export const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    setProducts:(state, action) => {
      state.products = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchBasket.fulfilled, (state, action) => {
      state.products = action.payload
    })
  }
})

export const basketActions = basketSlice.actions
export const basketReducer = basketSlice.reducer