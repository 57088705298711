import React, {
  useCallback, useEffect, useRef, useState 
} from 'react'

import { BtnFilters } from 'src/features/catalog/BtnFilters'
import styled from 'styled-components'
import { ProductCard } from 'src/features/catalog/ProductCard'
import { BackButton } from 'src/features/catalog/BackButton'
import { Filters } from 'src/features/catalog/Filters'
import { SearchInput } from 'src/features/catalog/SearchInput'
import { catalogApi } from 'src/features/api'
import { useLoadOnScroll } from 'src/hooks/useLoadOnScroll'
import { useFilters } from 'src/hooks/useFilters'
import {
  useNavigate, useParams 
} from 'react-router-dom'
import { Preloader } from 'src/ui/Preloader'
import { ROUTES } from 'src/constants/routes'

export const CatalogPage = ({ className = '' }) => {
  const { id: categoryId } = useParams()
  const navigate = useNavigate()

  const [fullScreenLoading, setFullScreenLoading] = useState(true)
  const [categoryInfo, setCategoryInfo] = useState({})
  const [subCategories, setSubCategories] = useState([])
  const [products, setProducts] = useState([])
  const [totalProductsCount, setTotalProductsCount] = useState(null)
  
  const [filters, setFilters] = useFilters({ subcategory: null })

  const isFirstLoadingRef = useRef(true)
  
  const fetchInfoAboutCategory = useCallback(async () => {
    const response = await catalogApi.getInfoAboutCategory(categoryId)

    setCategoryInfo(response.data.attributes)
  }, [categoryId])

  const fetchProducts = useCallback(async (reload) => {
    const subcategory = filters.subcategory

    const params = {
      paginationStart: reload ? 0 : products.length,
      categoryId: subcategory || categoryId
    }

    const response = subcategory ? await catalogApi.getProductsBySubCategory(params): await catalogApi.getProductsByCategory(params)
  
    setTotalProductsCount(response.meta.pagination.total)
    setProducts(state => (reload ? [...response.data] : [...state, ...response.data]))
  }, [products, categoryId, filters.subcategory])

  const fetchSubCategories = useCallback(async () => {
    const response = await catalogApi.getSubCategoriesByCategory({ categoryId })

    setSubCategories(response.data.map(category => ({
      id: category.id, label: category.attributes.name 
    })))
  }, [categoryId])

  const setSubCategory = useCallback((id) => {
    setFilters({ subcategory: filters.subcategory == id ? null : id })
  }, [filters.subcategory])

  const handleBackClick = useCallback(() => {
    navigate(ROUTES.home)
  }, [])

  useLoadOnScroll(fetchProducts)

  useEffect(() => {
    setFullScreenLoading(true)

    const fetchData = async () => {
      if (isFirstLoadingRef.current) {
        await Promise.all([fetchInfoAboutCategory(), fetchProducts(true), fetchSubCategories()])
      } else {
        await fetchProducts(true)
      }

      setFullScreenLoading(false)
    }

    fetchData()
  }, [filters.subcategory])

  return (
    <CatalogBox className={className}>
      <TopRow>
        <BackButton onClick={handleBackClick} />

        <StyledSearchInput isPlaceholder />

        {/* <Filters /> */}
      </TopRow>


      {!!categoryInfo.name &&
          <Title>
            {categoryInfo.name}
            {totalProductsCount !== null &&
              <span>
                {totalProductsCount}
              </span>
            }
          </Title>
      }

      {
        !!subCategories.length &&
            <StyledBtnFilters
              options={subCategories}
              active={filters.subcategory}
              onSelect={setSubCategory}
            />
      }

      {
        !fullScreenLoading &&
          <CardsBox>
            {products.map(product => (
              <ProductCard
                data={product}
                key={product.id}
              />
            ))}
          </CardsBox>
      }

      {
        fullScreenLoading &&
        <PreloaderBox>
          <Preloader />
        </PreloaderBox>
      }

      
    </CatalogBox>
  )
}

const CatalogBox = styled.div`
  padding: 16px 16px 90px;
`

const Title = styled.p`
  margin-top: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.text.main};
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
    font-weight: 400;
    font-size: 17px;
    letter-spacing: -0.5px;
    color: ${props => props.theme.text.quaternary};
  }
`

const StyledBtnFilters = styled(BtnFilters)`
  margin-top: 18px;
  width: calc(100% + 32px);
  margin-left: -16px;
  padding: 0 16px;
`

const CardsBox = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 23px 16px;
`

const TopRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const StyledSearchInput = styled(SearchInput)`
  flex: 1;
  margin: 0 8px;
`

const PreloaderBox = styled.div`
  height: calc(100vh - 350px);
  position: relative;
`
