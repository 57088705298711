import { useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { catalogApi } from '../api'

import {
  basketActions, fetchBasket 
} from './basketSlice'
import { getBasketProducts } from './selectors'

export const useBasket = () => {
  const dispatch = useDispatch()
  
  const userId = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || 229165046

  const products = getBasketProducts()

  const addProduct = useCallback(async (product) => {
    const existedProductIndex = products.map(p => p.id).indexOf(product.id)

    let newProducts

    if (existedProductIndex !== -1) {
      newProducts = [...products.slice(0, existedProductIndex), {
        ...products[existedProductIndex], count: products[existedProductIndex].count + 1 
      }, ...products.slice(existedProductIndex + 1)]
    } else {
      newProducts = [...products, {
        ...product, count: 1 
      }]
    }

    dispatch(basketActions.setProducts(newProducts))

    catalogApi.addProductsToBasket({
      data: {
        cart_products: newProducts.map(product => ({
          product: product.id, count: product.count 
        })),
        userId
      } 
    })
  }, [products])

  const removeProduct = useCallback((product, full) => {
    const existedProductIndex = products.map(p => p.id).indexOf(product.id)
    const existedProduct = products[existedProductIndex]

    let newProducts

    if (existedProduct.count === 1 || full) {
      newProducts = [...products.slice(0, existedProductIndex), ...products.slice(existedProductIndex + 1)]
    } else {
      newProducts = [...products.slice(0, existedProductIndex), {
        ...products[existedProductIndex], 
        count: existedProduct.count - 1 
      }, ...products.slice(existedProductIndex + 1)]
    }

    dispatch(basketActions.setProducts(newProducts))

    catalogApi.addProductsToBasket({
      data: {
        cart_products: newProducts.map(product => ({
          product: product.id, count: product.count 
        })),
        userId
      } 
    })
  }, [products])

  const removeAll = useCallback(() => {
    dispatch(basketActions.setProducts([]))

    catalogApi.addProductsToBasket({
      data: {
        cart_products: [],
        userId
      } 
    })
  }, [])

  const loadBasket = useCallback(() => {
    dispatch(fetchBasket(userId))
  }, [])

  return {
    loadBasket,
    addProduct,
    removeProduct,
    removeAll
  }
}