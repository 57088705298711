const {
  useEffect, useRef 
} = require('react')

export const useLoadOnScroll = (load) => {
  const loadingRef = useRef(false)

  useEffect(() => {
    const startLoading = async () => {
      if (loadingRef.current) {
        return
      }

      if (window.innerHeight + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) + 600 > document.body.scrollHeight) {
        loadingRef.current = true

        try {
          await load()

          loadingRef.current = false
        } catch (error) {
          console.error(error)
        }   
      }
    }

    document.addEventListener('scroll', startLoading)

    return () => document.removeEventListener('scroll', startLoading)
  }, [load])
}