import React, {
  useEffect, useState
} from 'react'

import { Slider } from 'src/ui/Slider'
import styled from 'styled-components'
import { ProductOptions } from 'src/features/catalog/ProductOptions'
import { Button } from 'src/ui/Button'
import { BackButton } from 'src/features/catalog/BackButton'
import {
  useNavigate, useParams
} from 'react-router-dom'
import { Preloader } from 'src/ui/Preloader'
import { catalogApi } from 'src/features/api'
import { useBasket } from 'src/features/basket/useBasket'
import { getBasketProducts } from 'src/features/basket/selectors'
import { Counter } from 'src/ui/Counter'
import classNames from 'classnames'
import { ProductCard } from 'src/features/catalog/ProductCard'
import {
  useMutation, useQuery
} from '@tanstack/react-query'
import moment from 'moment/moment'

import { OrderBtn } from '../features/basket/BasketBtn'

export const OrderInfoPage = ({ className = '' }) => {
  const navigate = useNavigate()
  const { id: orderId } = useParams()
  const { loadBasket } = useBasket()
  const userId = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || 229165046

  const {
    data, error, isLoading
  } = useQuery({
    queryKey: ['getOrdersHistory'], queryFn: () => catalogApi.getOrdersHistory(), placeholderData: [], select: data => data.data
  })

  const {
    isLoading: addingToBasketInProgress, mutate: handleRepeatOrder
  } = useMutation({
    mutationFn: async (newTodo) => {
      const products = orderData.products.filter(product => !!product.id)

      return catalogApi.addProductsToBasket({
        data: {
          cart_products: products.map(product => ({
            product: product.id, count: product.count
          })),
          userId
        }
      })
    },
    onSuccess: () => {
      loadBasket()
      navigate('/basket')
    }
  })

  if (!!isLoading) {
    return (
      <OrderInfoPageBox className={className}>
        {isLoading &&
          <PreloaderBox>
            <Preloader />
          </PreloaderBox>
        }
      </OrderInfoPageBox>
    )
  }
  const orderData = data.find(order => String(order.id) === orderId)

  return (
    <OrderInfoPageBox className={className}>
      <TopRow>
        <BackButton />

        <Title>{moment(orderData.createdAt).format('D.MM.YYYY г. в HH:mm')}</Title>

        <TopRowEmptyDiv />
      </TopRow>

      <CardsBox>
        {orderData.products.map(product => (
          <ProductCard
            data={product}
            key={product.id}
            withoutBtns
            noNeedToExtractData
          />
        ))}
      </CardsBox>

      <OrderBtn
        text="Повторить заказ"
        onClick={handleRepeatOrder}
        disabled={addingToBasketInProgress}
      />
    </OrderInfoPageBox>
  )
}

const OrderInfoPageBox = styled.div`
  padding: 16px 16px 90px;
`

const TopRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: ${props => props.theme.text.main};
`

const TopRowEmptyDiv = styled.div`
  width: 32px;
`

const CardsBox = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 23px 16px;
`

const PreloaderBox = styled.div`
  height: calc(100vh - 350px);
  position: relative;
`