import { useSelector } from 'react-redux'

export const getAllCategories = () => {
  return useSelector(state => state.catalog.categories)
}

export const getMainBanner = () => {
  return useSelector(state => state.catalog.mainBanner)
}

export const getBotSettings = () => {
  return useSelector(state => state.catalog.botSettings)
}