export const LIGHT_THEME = {
  backgrounds: {
    main: '#ffffff',
    secondary: '#EFEFF4',
    tertiary: '#037EE5',
    quaternary: '#EFEFF0',
  },
  
  text: {
    main: '#000000',
    secondary: '#ffffff',
    tertiary: '#037EE5',
    quaternary: '#7E7E82',
    buttons: '#ffffff'
  },

  inputs: {
    background: 'rgba(118, 118, 128, 0.12)',
    color: 'rgba(60, 60, 67, 1)',
    placeholder: 'rgba(60, 60, 67, 0.6)'
  }
}

export const DARK_THEME = {
  backgrounds: {
    main: '#1C1C1D',
    secondary: '#000000',
    tertiary: '#037EE5',
    quaternary: '#000000'
  },
  
  text: {
    main: '#ffffff',
    secondary: '#000000',
    tertiary: '#037EE5',
    quaternary: '#7E7E82',
    buttons: '#ffffff'
  },

  inputs: {
    background: '#000000',
    color: '#ffffff',
    placeholder: 'rgba(255, 255, 255, 0.6);'
  }
}