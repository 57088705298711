import React from 'react'

import styled from 'styled-components'

import { ReactComponent as CheckIcon } from '@/images/icons/check.svg'

export const CheckboxSelectIOS = ({
  className = '', options = [], checked, onChange 
}) => {
  
  return (
    <CheckboxSelectIOSBox className={className}>
      {options.map(option => (
        <Option
          key={option.value}
          onClick={() => onChange(option.value)}
        >
          {!!option.img &&
            <OptionImg src={option.img} />
          }

          {option.label}

          {checked === option.value &&
            <StyledCheckIcon />
          }
        </Option>
      ))}
    </CheckboxSelectIOSBox>
  )
}

const CheckboxSelectIOSBox = styled.div`
  width: 100%;
  background: ${props => props.theme.backgrounds.main};
  border-radius: 8px;
  padding-left: 17px;
`

const Option = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};
  padding-right: 16px;
  cursor: pointer;
  transition: all 0.4s linear;
  
  &:hover {
    filter: brightness(110%);
  }
  
  &:focus, &:active {
    filter: brightness(120%);
  }

  & ~ & {
    border-top: 0.33px solid rgba(60, 60, 67, 0.29);
  }
`

const OptionImg = styled.img`
  margin-right: 16px;
  width: 24px;
  height: 24px;
  object-fit: contain;
`

const StyledCheckIcon = styled(CheckIcon)`
  margin-left: auto;
  
  path {
    stroke: #037EE5;
  }
`