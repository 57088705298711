import React, {
  useEffect, useState 
} from 'react'

import styled from 'styled-components'
import { Checkbox } from 'src/ui/Checkbox'

import filtersImg from '@/images/icons/filters.svg'

import { BackButton } from './BackButton'
import { RangeFilter } from './RangeFilter'

const DATA = {
  sorters: [
    {
      name: 'Популярное',
      value: 'popular'
    },
    {
      name: 'Новое',
      value: 'new'
    },
  ],
  filters: [
    {
      name: 'Категория',
      type: 'checkbox',
      options: [
        {
          name: 'Юбки',
          value: 'popular'
        },
        {
          name: 'Жакеты',
          value: 'new'
        },
        {
          name: 'Трикотаж',
          value: 'popular 1'
        },
        {
          name: 'Футболки и топы',
          value: 'new 2'
        },
      ]
    },
    {
      name: 'Размер',
      type: 'checkbox',
      options: [
        {
          name: 'XS',
          value: 'popular'
        },
        {
          name: 'S',
          value: 'new'
        },
        {
          name: 'L',
          value: 'popular 1'
        },
        {
          name: 'M',
          value: 'new 2'
        },
      ]
    },
    {
      name: 'Цена, руб',
      type: 'range'
    }
  ]
}

export const Filters = ({
  className = '', filters = DATA 
}) => {
  const [opened, toggleOpen] = useState(false)

  const handleToggleClick = () => {
    toggleOpen(true)
  }

  const handleBackBtnClick = () => {
    toggleOpen(false)
  }

  useEffect(() => {
    if (opened) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [opened])

  return (
    <>
      <FiltersToggleBox
        className={className}
        onClick={handleToggleClick}
      >
        <Img src={filtersImg} />
      </FiltersToggleBox>

      {
        opened &&
        <FiltersBox>
          <TopRow>
            <BackBtnBox>
              <BackButton onClick={handleBackBtnClick} />
            </BackBtnBox>
            

            <Title>
              Фильтры
            </Title>

            <CancelBtn>Очистить</CancelBtn>
          </TopRow>

          {
            !!filters.sorters &&
              <FiltersGroupBox>
                <FiltersGroupTitle>
                  Сортировать по
                </FiltersGroupTitle>

                {
                  filters.sorters.map(sorter => (
                    <StyledCheckbox
                      key={sorter.name}
                      label={sorter.name}
                    />
                  ))
                }
              </FiltersGroupBox>
          }

          {
            filters?.filters?.map(filter => (
              <FiltersGroupBox key={filter.name}>
                <FiltersGroupTitle>
                  {filter.name}
                </FiltersGroupTitle>

                {
                  filter.type === 'checkbox' &&
                  filter.options.map(option => (
                    <StyledCheckbox
                      key={option.name}
                      label={option.name}
                    />
                  ))
                }

                {
                  filter.type === 'range' &&
                  <RangeFilter />
                }
              </FiltersGroupBox>
            ))
          }
          
        </FiltersBox>
      }
    </>
  )
}

const FiltersToggleBox = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`

const Img = styled.img`
  width: 24px;
  height: 24px;
`

const FiltersBox = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: ${props => props.theme.backgrounds.secondary};
  z-index: 2;
  padding: 16px;
  overflow: auto;
`

const TopRow = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${props => props.theme.text.main};
`

const CancelBtn = styled.span`
  width: 68px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.tertiary};
  cursor: pointer;
  transition: all 0.4s linear;
  
  &:hover {
    filter: brightness(110%);
  }
  
  &:focus, &:active {
    filter: brightness(120%);
  }
`

const BackBtnBox = styled.div`
  width: 68px;
`

const FiltersGroupBox = styled.div`
  width: 100%;
  padding: 16px;
  background: ${props => props.theme.backgrounds.main};
  border-radius: 8px;

  & ~ & {
    margin-top: 16px;
  }
`

const FiltersGroupTitle = styled.p`
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.text.tertiary};
`

const StyledCheckbox = styled(Checkbox)`
  & ~ & {
    margin-top: 16px;
  }
`