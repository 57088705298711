import React from 'react'

import classNames from 'classnames'
import {
  useLocation, useNavigate
} from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import styled from 'styled-components'
import { SvgIcon } from 'src/ui/svg/SvgIcon'

import { formatPrice } from '../../utils/formatters'

import { getBasketProducts } from './selectors'

export const OrderBtn = ({
  className, text, onClick, disabled
}) => {
  return (
    <OrderBtnBox className={classNames(className, { disabled })}>
      <Button onClick={onClick}>
        {text}
      </Button>
    </OrderBtnBox>
  )
}

export const BasketBtn = ({ className = '' }) => {
  const basketProducts = getBasketProducts()
  const location = useLocation()
  const navigate = useNavigate()

  const amount = basketProducts.reduce((r, p) => r += p.price * (p.count || 1), 0)

  const handleBasketClick = () => {
    navigate(ROUTES.basket)
  }

  const handleCatalogClick = () => {
    navigate(ROUTES.home)
  }

  const handleProfileClick = () => {
    navigate(ROUTES.profile)
  }

  const getActiveTab = () => {
    if (location.pathname === '/profile') {
      return 'profile'
    }

    return 'catalog'
  }

  if (location.pathname === '/basket' || location.pathname.startsWith('/order')) {
    return null
  }

  if (location.pathname !== '/basket') {
    return (
      <TabsBox>
        <Tab onClick={handleCatalogClick}>
          <TabIcon
            type="catalog"
            color={getActiveTab() === 'catalog' ? '#037EE5' : undefined}
          />
          <TabLabel className={classNames({ active: getActiveTab() === 'catalog' })}>Каталог</TabLabel>
        </Tab>

        <Tab onClick={handleBasketClick}>
          <TabIcon
            type="basket"
            color={getActiveTab() === 'basket' ? '#037EE5' : undefined}
          />

          {!!basketProducts.length && <TabCount>{basketProducts.length}</TabCount>}
          <TabLabel className={classNames({ active: getActiveTab() === 'basket' })}>Корзина</TabLabel>
        </Tab>

        <Tab onClick={handleProfileClick}>
          <TabIcon
            type="profile"
            color={getActiveTab() === 'profile' ? '#037EE5' : undefined}
          />
          <TabLabel className={classNames({ active: getActiveTab() === 'profile' })}>Профиль</TabLabel>
        </Tab>
      </TabsBox>
    )
  }

  return (
    <OrderBtn
      className={className}
      text={`Корзина - ${formatPrice(amount)} ₽`}
      onClick={handleBasketClick}
    />
  )
}

const OrderBtnBox = styled.div`
  background: ${props => props.theme.backgrounds.main};
  height: 83px;
  box-shadow: 0px -0.33px 0px #A6A6AA;
  padding: 7px 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`

const TabsBox = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  box-shadow: 0px -0.33px 0px #A6A6AA;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 40px;
  justify-content: space-between;
  background: ${props => props.theme.backgrounds.main};
`

const Tab = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`

const TabIcon = styled(SvgIcon)`
  width: 24px;
  height: 24px;
`

const TabLabel = styled.p`
  margin-top: 4px;
  color: ${props => props.theme.text.quaternary};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;

  &.active {
    color: ${props => props.theme.backgrounds.tertiary};
  }
`

const TabCount = styled.div`
  position: absolute;
  top: -2px;
  right: 8px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #B80B0B;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  color: white;
`

const Button = styled.button`
  width: 100%;
  height: 56px;
  background: ${props => props.theme.backgrounds.tertiary};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.buttons};
  cursor: pointer;
  transition: all 0.4s linear;
  
  &:hover {
    filter: brightness(110%);
  }
  
  &:focus, &:active {
    filter: brightness(120%);
  }

  ${OrderBtnBox}.disabled & {
    background: #7E7E82;
    pointer-events: none;
  }
`