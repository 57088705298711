import React from 'react'

import styled from 'styled-components'

export const Preloader = ({ className = '' }) => {
  return (
    <PreloaderBox className={className}>
      <Dots />
    </PreloaderBox>
  )
}

const PreloaderBox = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`

const Dots = styled.div`
  @keyframes dotFlashing {
    0% {
      background-color: ${props => props.theme.text.tertiary};
    }

    50%,
    100% {
      background-color: rgba(3, 126, 229, 0.3);
    }
  }

  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${props => props.theme.backgrounds.tertiary};
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;

  &::before, &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: ${props => props.theme.text.tertiary};
  }

  &::before {
    left: -32px;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}

  &::after {
    left: 32px;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }
`