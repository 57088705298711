import { configureStore } from '@reduxjs/toolkit'
import { basketReducer } from 'src/features/basket/basketSlice'
import { catalogReducer } from 'src/features/catalog/catalogSlice'

import { appReducer } from '../features/app/appSlice'

export const store = configureStore({
  reducer: { 
    app: appReducer,
    basket: basketReducer,
    catalog: catalogReducer
  }, 
})