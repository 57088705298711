import React from 'react'

import { Link } from 'react-router-dom'
import { Button } from 'src/ui/Button'
import { extractImgUrl } from 'src/utils/extractors'
import styled from 'styled-components'
import { SvgIcon } from 'src/ui/svg/SvgIcon'
import classNames from 'classnames'

import { ReactComponent as MinusIcon } from '@/images/icons/minus.svg'
import { ReactComponent as PlusIcon } from '@/images/icons/plus.svg'

import { getBasketProducts } from '../basket/selectors'
import { useBasket } from '../basket/useBasket'
import { formatPrice } from '../../utils/formatters'

export const ProductCard = React.memo(({
  className = '', data, withoutBtns, noNeedToExtractData
}) => {
  const extractedData = noNeedToExtractData ? data : {
    id: data.id, ...data.attributes
  }

  const products = getBasketProducts()

  const {
    addProduct, removeProduct
  } = useBasket()

  const productInBasket = products.find(product => product.id === Number(data.id))
  const productCountInBasket = productInBasket?.count || 0

  const handleAddProductClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    addProduct(extractedData)
  }

  const handleRemoveProductClick = () => {
    removeProduct(extractedData)
  }

  const amount = productInBasket?.price * productCountInBasket

  return (
    <ProductCardBox
      className={className}
      to={`/product/${data.id}`}
    >
      <ImgBox>
        <ProductImg src={extractedData.images?.[0]?.url || extractImgUrl(extractedData.images?.data?.[0])} />
      </ImgBox>

      <Title>
        {extractedData.name}
      </Title>

      <PriceRow>
        <Price>
          {formatPrice(extractedData.price)} {extractedData.unit}
        </Price>
        {!!extractedData.old_price &&
        <OldPrice>
          {extractedData.old_price} {extractedData.unit}
        </OldPrice>
        }
      </PriceRow>

      {
        productCountInBasket === 0 && !withoutBtns &&
          <BusketButton onClick={handleAddProductClick}>
            <BasketIcon
              type="basket"
              color="white"
            />
            В корзину
          </BusketButton>
      }

      {
        productCountInBasket !== 0 && !withoutBtns &&
          <ProductCounter
            count={productCountInBasket}
            onPlus={handleAddProductClick}
            onMinus={handleRemoveProductClick}
            amount={amount}
          />
      }
    </ProductCardBox>
  )
})

const ProductCardBox = styled(Link)`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  padding: 0 8px 8px;
`

const ImgBox = styled.div`
  width: calc(100% + 16px);
  margin-left: -8px;
  height: 136px;
  /* background:rgba(118, 118, 128, 0.08); */
  border-radius: 8px;
  background: #FAFAFA;
`

const ProductImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Title = styled.p`
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.6px;
  color: #000;
`

const PriceRow = styled.div`
  margin-top: auto;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Price = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.8px;
  color: #000;
`

const OldPrice = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.5px;
  text-decoration-line: line-through;
  color: ${props => props.theme.text.quaternary};
`

const BusketButton = styled(Button)`
  width: 100%;
  height: 40px;
  font-size: 14px;
`

const BasketIcon = styled(SvgIcon)`
  margin-right: 8px;
  width: 18px;
  height: 18px;
`

const ProductCounter = ({
  className, count, amount, onMinus, onPlus, size
}) => {
  const handleMinusClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    onMinus(e)
  }

  const handlePlusClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    onPlus(e)
  }

  return (
    <CounterBox className={classNames(className, { small: size === 'small' })}>
      <ControlBox onClick={handleMinusClick}>
        <MinusIcon />
      </ControlBox>

      <Divider />

      <CountBox>
        <Count>{count} шт</Count>
        <Amount>
          {formatPrice(amount)} ₽
        </Amount>
      </CountBox>

      <Divider />

      <ControlBox onClick={handlePlusClick}>
        <PlusIcon />
      </ControlBox>
    </CounterBox>
  )
}

const CounterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background: ${props => props.theme.backgrounds.tertiary};
  border-radius: 8px;
  padding: 0 8px;

  &.small {
    height: 28px;
    min-width: 100px;
    background: transparent;
  }
`

const ControlBox = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.backgrounds.tertiary};
  border-radius: 8px;
`

const CountBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Count = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: white;
`

const Amount = styled.p`
  margin-top: 4px;
  color: rgba(255, 255, 255, 0.60);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`
const Divider = styled.div`
  width: 0.4px;
  height: 32px;
  background: #FFFFFF66;
`